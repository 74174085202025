import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "MainLayout",
        component: () => import("@/layouts/MainLayout.vue"),
        redirect: {
            // name: "MainLayoutHome",
            name: "AuthLayoutLogin", //vaqtinchalik qo'yib turibmiz
        },
        children: [
            {
                path: "home/",
                name: "MainLayoutHome",
                component: () => import("@/views/HomeView.vue"),
                // redirect: {name: "MainLayoutAccount"},
                // children: [
                //     {
                //         path: "account",
                //         name: "MainLayoutAccount",
                //         component: () => import("@/views/account.vue"),
                //     },
                //     {
                //         path: "setting",
                //         name: "MainLayoutSetting",
                //         component: () => import("@/views/setting.vue"),
                //     },
                // ],
            },
            {
                path: "DoctorTable",
                name: "MainLayoutDoctorTable",
                component: () => import("@/views/doctorTable/index.vue"),
            },
            {
                path: "Applications",
                name: "MainLayoutApplications",
                component: () => import("@/views/applications/index.vue"),
            },
            {
                path: "MedicalTechniques",
                name: "MainLayoutMedicalTechniques",
                component: () => import("@/views/medicalTechniques/index.vue"),
            },
            {
                path: "medicalEquipments",
                name: "MainLayoutMedicalEquipments",
                component: () => import("@/views/medicalEquipments/index.vue"),
            },
            {
                path: "MedicalServices",
                name: "MainLayoutMedicalServices",
                component: () => import("@/views/services/index.vue"),
            },
            {
                path: "MedicalAccount",
                name: "MainLayoutMedicalAccount",
                component: () => import("@/views/account/index.vue"),
            },
            {
                path: "ApplicationsSend",
                name: "MainLayoutApplicationsSend",
                component: () => import("@/views/applicationsSend/index.vue"),
            },
            {
                path: "ConfirmList",
                name: "MainLayoutConfirmList",
                component: () => import("@/views/confirmList/index.vue"),
            },
        ],
    },

    {
        path: "/auth",
        name: "AuthLayout",
        component: () => import("@/layouts/AuthLayout.vue"),
        redirect: {
            name: "AuthLayoutLogin",
        },
        children: [
            {
                path: "login",
                name: "AuthLayoutLogin",
                component: () => import("@/views/login.vue"),
            },
            {
                path: "Eror404",
                name: "AuthLayoutEror404",
                component: () => import("@/views/Eror.vue"),
            },
            {
                path: "Eror505",
                name: "AuthLayoutEror505",
                component: () => import("@/views/Eror505.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;




// const router = new VueRouter({
//   routes
// })

// export default router
